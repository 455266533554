@charset "UTF-8";

.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}

.swipe-wrap {
  overflow: hidden;
  position: relative;
}

.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
